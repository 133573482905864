<template>
  <div class="loading">
    <van-loading 
      v-show="true" 
      size="40px" 
      color="#1FC9C2"/>
  </div>
</template>

<script>
import Vue from 'vue'
import {Loading} from 'vant'
Vue.use(Loading)
export default {

}
</script>

<style lang="less" scoped>
.loading{
  position: fixed;
  top:0;
  left:0;
  z-index:9999;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.6);
  .van-loading{
    width: 100%;
    position: fixed;
    top: 50%;
    left: 0;
    margin-top: -15px;
    z-index: 9999;
    text-align: center;
  }
}
</style>
