/*
 * @Author: lzyy
 * @Date: 2021-01-18 11:20:00
 * @LastEditors: lzyy
 * @LastEditTime: 2021-01-18 11:20:00
 * @Description:
 */

import axios from '@/api/http'
import base from './base'
import api from './src'

const transParams = ({ code, params}) => {
  let formData = `code=${code}&client='h5'&json=${JSON.stringify(params)}`
  return formData
}

const Api = new class {
  constructor() {
    Object.keys(api).forEach(module => {
      this[module] = {}
      api[module].forEach(rApi => {
        const { url, type = "post", code } = rApi
        this[module][url] = (param = {}) => {
          const { params = {}, headers } = param
          if (headers) {
            let config = {
              "headers": { ...headers }
            }
            return new Promise((resolve, reject) => {
              axios[type](`${base.url}`, transParams({
                code,
                params,
              }), config).then(res => {
                const { data: response } = res
                const { errorCode, data } = response
                if (errorCode === '0') {
                  resolve(data)
                }else{
                  reject(response)
                }
              }).catch(e =>{console.log(e)})
            })
          }
          return new Promise((resolve, reject) => {
            axios[type](`${base.url}`, transParams({
              code,
              params,
            })).then(res => {
              const { data: response } = res
              const { errorCode, data } = response
              if (errorCode === '0') {
                resolve(data)
              }else{
                reject(response)
              }
            }).catch(e =>{console.log(e)})
          })
        }
      })
    })
  }
}()
// 导出接口
export default Api