<template>
  <div id="app">
    <router-view></router-view>
    <Loading v-show="LOADING"></Loading>
  </div>
</template>

<script>
import Loading from '@/components/loading'
import {mapState} from 'vuex'
export default {
  name: '',
  components:{
    Loading,
  },
  data () {
    return {

    }
  },
  computed:{
    ...mapState([
      'LOADING'
    ]),
  },
  methods:{

  },
  created(){

  },
  mounted(){

  },
  watch:{

  }
}
</script>

<style lang="less">

</style>
