<template>
  <div class="index">
    <div class="header">
      <h5><img src="../assets/images/logo.png"></h5>
    </div>
    <div class="nav">
      <van-sticky>
      <van-tabs 
        v-model="activeCategory"
        :ellipsis="false"
        @click="changeBlogNav">
        <van-tab 
          v-for="item in blogNav"
          :key="item.id"
          :title="item.englishName">
        </van-tab>
      </van-tabs>
      </van-sticky>
    </div>
    <div class="banner">
      <van-swipe 
        :autoplay="60000" 
        :width="swipeWidth" 
        :show-indicators="false">
        <van-swipe-item 
          v-for="item in bannerList" 
          :key="item.code">
          <div class="list">
            <a :href="item.url" target="_blank">
            <img :src="imgUrl+item.pic">
            </a>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="box">
      <van-list 
        v-model="loading" 
        :finished="finished" 
        finished-text="END" 
        :immediate-check="false" 
        :offset="50" 
        @load="onLoad">
        <div class="list" 
          v-for="item in blogList" 
          :key="item.id"
          :class="{hasAd : !!!item.title}">
          <a :href="item.urlH5" target="_blank" v-if="!!item.title">
          <div class="top">
            <p>{{item.updater}}</p>
            <span>{{item.updateDatetime}}</span>
          </div>
          <div class="mid">
            <div class="txt">
              <h5>{{item.title}}</h5>
              <p>{{item.subtitle}}</p>
              <div class="icon">
                <span>
                  <img src="../assets/images/icon_1.png">
                  {{item.likes}}
                </span>
                <span>
                  <img src="../assets/images/icon_2.png">
                  {{item.visits}}
                </span>
              </div>
            </div>
            <div class="img">
              <van-image 
                fit="contain" 
                :src="imgUrl+item.articlePic"/>
              <div class="tag" v-if="!!item.blogTagList">
                <span 
                  v-for="(items, index) in item.blogTagList" 
                  :key="index">
                  {{items}}
                </span>
              </div>  
            </div>
          </div>
          </a>
          <a :href="item.url" target="_blank" v-if="!!!item.title">
          <div class="ad">
            <img :src="imgUrl+item.pic">
          </div>
          </a>
        </div>
      </van-list>
    </div>
    <div class="foot">
      <div class="section">
        <div class="list">
          <h5>Cardtime</h5>
          <a href="about.html"><p>About us</p></a>
          <a href="contact.html"><p>Contact us</p></a>
          <a href="business.html"><p>Business</p></a>
        </div>
        <div class="list">
          <h5>{{footNav1.englishName}}</h5>
          <p 
            v-for="(item, index) in footNav1.blogList" 
            :key="index">
            <a :href="item.urlH5">
              {{item.artTitle}}
            </a>
          </p>
        </div>
      </div>
      <div class="section" 
        v-for="item in footNav2" 
        :key="item.id">
        <div class="list">
          <h5>{{item.englishName}}</h5>
          <p 
            v-for="(items, index) in item.blogList" 
            :key="index">
            <a :href="items.urlH5">
              {{items.artTitle}}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Swipe, SwipeItem, List, Image as VanImage, Sticky } from 'vant'
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(VanImage)
Vue.use(Sticky)
export default {
  name: '',
  components:{

  },
  data () {
    return {
      //图片地址
      imgUrl: imgUrl,
      //banner
      bannerList:[],
      blogNav: [
        {
          id: 0,
          englishName: 'All'
        }
      ],
      activeCategory: 0,
      categoryId: 0,
      blogList:[],
      blogAd:[],
      footNav1:{},
      footNav2:[],
      //当前页
      page:1,
      //每页个数
      pageNumber:10,
      loading:false,
      finished:false,
      loadMore: false,
      pushAdNum: 0,
    }
  },
  computed:{
    swipeWidth(){
      return window.screen.width - 30
    },
  },
  methods:{
    async getHost(url){
      if(url.indexOf('test') != -1){
        //测试
        return `//${url.replace('h5test.', 'pctest.')}/blog`
      }else{
        //正式
        return `//${url.replace('h5.', '')}/blog`
      }
    },
    //format时间格式转换
    async formatTime(format){
      let time = new Date(format)
      let Y = time.getFullYear() + '-'
      let M = (time.getMonth() + 1 < 10 ? '0'+(time.getMonth() + 1) : time.getMonth() + 1) + '-'
      let D = (time.getDate() < 10 ? '0'+time.getDate() : time.getDate()) + ' '
      let h = (time.getHours() < 10 ? '0'+time.getHours() : time.getHours()) + ':'
      let m = (time.getMinutes() < 10 ? '0'+time.getMinutes() : time.getMinutes()) + ':'
      let s = time.getSeconds() < 10 ? '0'+time.getSeconds() : time.getSeconds()
      return Y+M+D+h+m+s
    },
    //获取博客banner
    async getBlogBanner(){
      const res = await this.$api.home.getBlogBanner({
        params:{
          subsidiaryCode: 'blog000000001',
          location: 'blog_h5_index',
        }
      })
      this.bannerList = res
    },
    //获取博客分类
    async getBlogNav(){
      const res = await this.$api.home.getBlogNav({
        params:{
          subsidiaryCode: 'blog000000001',
          status: 1,
        }
      })
      for(let i=0; i<res.length; i++){
        this.blogNav.push(res[i])
      }
    },
    //切换博客分类
    async changeBlogNav(){
      this.categoryId = this.blogNav[this.activeCategory].id
      this.finished  = false
      this.loadMore = false
      this.page = 1
      this.blogList = []
      this.pushAdNum = 0
      this.getBlogList()
    },
    //获取博客列表
    async getBlogList(){
      const res = await this.$api.home.getBlogList({
        params:{
          subsidiaryCode: 'blog000000001',
          start: this.page,
          limit: this.pageNumber,
          type: this.categoryId,
        }
      })
      this.loading = false
      let url = await this.getHost(window.location.host)
      if(res.list.length>0){
        this.loadMore = true
        for(let i=0; i<res.list.length; i++){
          res.list[i].urlH5 = `${url}/${res.list[i].urlH5}`
          res.list[i].updateDatetime = await this.formatTime(res.list[i].updateDatetime)
          this.blogList.push(res.list[i])
        }
        await this.getBlogAd()
        this.pushAd()
      }else{
        this.loadMore = false
        this.finished = true
      }
    },
    //上拉分页
    onLoad(){
      if(this.loadMore){
        this.page++;
        this.getBlogList()
      }
    },
    //获取播客广告
    async getBlogAd(){
      const res = await this.$api.home.getBlogAd({
        params:{
          subsidiaryCode: 'blog000000001',
          blogType: this.categoryId,
        }
      })
      this.blogAd = res
    },
    //插入广告
    async pushAd(){
      for(let i=0; i<this.blogAd.length; i++){
        if(this.blogAd[i].orderNo >= (this.page-2)*this.pageNumber && this.blogAd[i].orderNo <= this.page*this.pageNumber){
          this.blogList.splice(this.blogAd[i].orderNo+this.pushAdNum, 0, this.blogAd[i])
          this.pushAdNum += 1
        }
      }
    },
    //获取底部导航
    async getFootNav(){
      const res = await this.$api.home.getFootNav({
        params:{
          subsidiaryCode: 'blog000000001',
        }
      })
      let url = await this.getHost(window.location.host)
      for(let i=0; i<res.length; i++){
        for(let j=0; j<res[i].blogList.length; j++){
          res[i].blogList[j].urlH5 = `${url}/${res[i].blogList[j].urlH5}`
        }
      }
      this.footNav1 = res[0]
      this.footNav2 = res.slice(1)
    }
  },
  created(){
    //获取博客banner
    this.getBlogBanner()
    //获取博客分类
    this.getBlogNav()
    //获取博客列表
    this.getBlogList()
    //获取底部导航
    this.getFootNav()
  },
  mounted(){
    
  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.index{
  width: 100%;
  .header{
    padding: 12px 0;
    position: relative;
    border-bottom: 1px solid #FAFBFC;
    h5{
      font-size: 17px;
      font-weight: 600;
      color: #333333;
      text-align: center;
      img{
        display: block;
        width: 70px;
        margin: 0 auto;
      }
    }
  }
  .banner{
    background-color: #f2f2f2;
  }
  .box{
    padding: 0 15px;
    background-color: #f2f2f2;
    .list{
      background: #FFFFFF;
      border-radius: 5px;
      padding: 0 15px;
      margin-bottom: 15px;
      .top{
        display: flex;
        justify-content: space-between;
        padding: 5px 0 8px 0;
        p{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          display: flex;
          align-items: center;
        }
        span{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
      .mid{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        .txt{
          width: 100%;
          padding-right: 15px;
          h5{
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin-bottom: 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          p{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .icon{
            display: flex;
            margin-top: 12px;
            span{
              display: flex;
              align-items: center;
              margin-right: 15px;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              img{
                width: 14px;
                margin-right: 5px;
              }
            }
          }
        }
        .img{
          width: 100px;
          .tag{
            span{
              display: inline-block;
              font-size: 10px;
              font-weight: 500;
              color: #1FC9C2;
              border: 1px solid #1FC9C2;
              border-radius: 6px;
              padding: 2px 6px;
              margin-top: 4px;
              margin-right: 4px;
            }
          }
        }
      }
      .ad{
        img{
          display: block;
          width: 100%;
        }
      }
    }
    .list.hasAd{
      padding: 0;
      background-color: none;
    }
  }
  .foot{
    background: #E2EAFB;
    padding: 15px;
  }
  .section{
    display: flex;
    margin-top: 20px;
    .list{
      h5{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 20px;
      }
      p{
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 15px;
        a{
          color: #666666;
        }
      }
    }
    .list:first-child{
      margin-right: 60px;
    }
  }
}
</style>
<style lang="less">
.index{
  .nav{
    .van-tabs__line{
      bottom: 20px;
      background-color: #1FC9C2;
    }
    .van-tab__text--ellipsis{
      font-size: 12px;
      font-weight: 500;
      color: #333;
    }
    .van-tab--active{
      .van-tab__text{
        color: #1FC9C2;
      }
    }
  }
  .banner{
    .van-swipe{
      padding-left: 15px;
      padding-top: 5px;
      padding-bottom: 5px;
      .van-swipe-item{
        .list{
          padding: 5px;
          img{
            width: 100%;
            box-shadow:5px 5px 20px 0px rgba(0,0,0,0.2);
            border-radius: 4px;
          }
        }
      }
    }
  }
  .box{
    .van-image{
      width: 100px;
      height: 62px;
      img{
        border-radius: 6px;
      }
    }
  }
}
</style>
